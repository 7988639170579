<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ analysis.session_class.name }}
        </el-breadcrumb-item>
        <el-breadcrumb-item> {{ myTitle }} </el-breadcrumb-item>
        <el-breadcrumb-item>
          Adaptive Analysis
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"> </Heading>
    <hr />
    <el-form label-width="140px">
      <el-form-item label="Class">
        <b>
          {{ analysis.session_class.name }}
        </b>
      </el-form-item>
      <el-form-item label="Teacher">
        <b
          class="mr-1"
          v-for="(teacher, index) in analysis.teachers"
          :key="teacher.id"
        >
          <span class="text-success">
            {{ user.displayName(teacher.first_name, teacher.last_name) }}
          </span>
          <span v-if="index < analysis.teachers.length - 1">
            ,
          </span>
        </b>
      </el-form-item>
      <el-form-item label="Total Students">
        <b>
          {{ analysis.total }}
        </b>
      </el-form-item>
      <el-form-item label="Pending">
        <b class="text-warning">
          {{ analysis.pending }}
        </b>
      </el-form-item>
      <el-form-item label="In Progress">
        <b>
          {{ analysis.in_progress }}
        </b>
      </el-form-item>
      <el-form-item label="Completed">
        <b class="text-success">
          {{ analysis.finished }}
        </b>
      </el-form-item>
    </el-form>
    <el-card
      style="margin:5px 0 10px 0"
      v-for="practice in analysis.exams"
      :key="practice.id"
    >
      <div class="practice">
        <div style="flex: 1;">
          <div class="small" style="font-size: 80%;">
            <h5>
              {{ practice.exam_title }}
            </h5>
          </div>
        </div>
        <div class="practice" style="width: 200px;">
          <div>
            <b v-if="practice.finished > 1">
              Students: {{ practice.finished }}
            </b>
            <b v-else>Student: {{ practice.finished }}</b>
          </div>
          <div class="question-content">
            <router-link
              :to="{
                name: analysisRouter,
                params: { id: practice.exam_id },
                query: {
                  session_class_exam_id: courseId
                }
              }"
            >
              <el-button type="success" size="mini" plain>
                <i class="fas fa-eye" />
              </el-button>
            </router-link>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { instant, user } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import Class from "@/apis/class.js";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      analysis: null,
      adaptivePractice: null
    };
  },
  computed: {
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    myTitle() {
      return this.analysis ? this.analysis.exam_title : "-";
    },
    courseId() {
      return this.$route.params.id;
    },
    session_class_exam_id() {
      return this.$route.query.session_class_exam_id;
    },
    exam_id() {
      return this.$route.query.exam_id;
    },
    isTeacher() {
      return this.$route.name === "TeacherAdaptiveAnalysis";
    },
    isAdmin() {
      return this.$route.name === "AdminAdaptiveAnalysis";
    },
    analysisRouter() {
      let router = "TeacherAdaptiveAnalysisDetail";
      if (this.isAdmin) {
        router = "AdminAdaptiveAnalysisDetail";
      }
      return router;
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      this.getClass(this.$route.params.id);
    }
  },

  methods: {
    async getClass(id) {
      const res = await Class.getAdaptiveExams(id, {
        session_class_exam_id: this.session_class_exam_id
      });
      this.analysis = res;
    }
  }
};
</script>

<style scoped>
.practice {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
</style>
